
import { Component, Vue } from 'vue-property-decorator'
import { siteConfig } from '@/config/site'
import { lang } from '@/lang'

@Component({
  components: {}
})
export default class ModuleF extends Vue {
  private get isLangCN () {
    return siteConfig.lang === 'CN'
  }

  private get text () {
    return {
      a: lang('NEW_LANDING_MODULE_F_1'),
      b: lang('NEW_LANDING_MODULE_F_2'),
      c: lang('NEW_LANDING_MODULE_F_3'),
      d: lang('NEW_LANDING_MODULE_F_4'),
      e: lang('NEW_LANDING_MODULE_F_5'),
      f: lang('NEW_LANDING_MODULE_F_6'),
      g: lang('NEW_LANDING_MODULE_F_7')
    }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import Subscribe from '../components/Subscribe.vue'
import { siteConfig } from '@/config/site'
import { lang } from '@/lang'

@Component({
  components: {
    Subscribe
  }
})
export default class ModuleC extends Vue {
  private get isLangCN () {
    return siteConfig.lang === 'CN'
  }

  private get text () {
    return {
      a: lang('NEW_LANDING_MODULE_E_1'),
      b: lang('NEW_LANDING_MODULE_E_2'),
      c: lang('NEW_LANDING_MODULE_E_3')
    }
  }
}

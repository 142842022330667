
import { Component, Vue } from 'vue-property-decorator'
import { siteConfig } from '@/config/site'
import { lang } from '@/lang'

@Component({
  components: {}
})
export default class ModuleH extends Vue {
  private get isLangCN () {
    return siteConfig.lang === 'CN'
  }

  private get text () {
    return {
      a: lang('NEW_LANDING_MODULE_H_1'),
      b: lang('NEW_LANDING_MODULE_H_2'),
      c: lang('NEW_LANDING_MODULE_H_3'),
      d: lang('NEW_LANDING_MODULE_H_4'),
      e: lang('NEW_LANDING_MODULE_H_5'),
      f: lang('NEW_LANDING_MODULE_H_6'),
      g: lang('NEW_LANDING_MODULE_H_7'),
      h: lang('NEW_LANDING_MODULE_H_8')
    }
  }

  private handlerConfirm () {
    window.open('https://forms.gle/9ncGoj4M9t4Nvk2v9', '_blank')
  }
}


import { Component, Vue } from 'vue-property-decorator'
import Subscribe from './Subscribe.vue'
import { lang } from '@/lang'

@Component({
  components: {
    Subscribe
  }
})
export default class HomeFooter extends Vue {
  private get text () {
    return {
      a: lang('HOME_FOOTER_1'),
      b: lang('HOME_FOOTER_2'),
      c: lang('HOME_FOOTER_3'),
      d: lang('HOME_FOOTER_4'),
      e: lang('HOME_FOOTER_5'),
      f: lang('HOME_FOOTER_6')
    }
  }
}

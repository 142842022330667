
import { Vue, Component } from 'vue-property-decorator'
import PriceOffCountdown from './components/PriceOffCountdown.vue'
import ModuleA from './module/ModuleA.vue'
import ModuleB from './module/ModuleB.vue'
import ModuleC from './module/ModuleC.vue'
import ModuleD from './module/ModuleD.vue'
import ModuleE from './module/ModuleE.vue'
import ModuleF from './module/ModuleF.vue'
import ModuleG from './module/ModuleG.vue'
import ModuleH from './module/ModuleH.vue'
import ModuleI from './module/ModuleI.vue'
import ModuleJ from './module/ModuleJ.vue'
import HomeFooter from './components/HomeFooter.vue'
import { priceOffConfig } from '@/config/priceOff'

@Component({
  components: {
    ModuleA,
    ModuleB,
    ModuleC,
    ModuleD,
    ModuleE,
    ModuleF,
    ModuleG,
    ModuleH,
    ModuleI,
    ModuleJ,
    HomeFooter,
    PriceOffCountdown
  }
})
export default class LandingConsole extends Vue {
  private get marginBottom () {
    return priceOffConfig.show ? '65px' : '0'
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { siteConfig } from '@/config/site'
import { lang } from '@/lang'

@Component({
  components: {}
})
export default class ModuleG extends Vue {
  private get isLangCN () {
    return siteConfig.lang === 'CN'
  }

  private get text () {
    return lang('NEW_LANDING_MODULE_G_1')
  }
}

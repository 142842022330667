
import { Component, Vue } from 'vue-property-decorator'
import { lang } from '@/lang'
import { siteConfig } from '@/config/site'

@Component({
  components: {}
})
export default class ModuleC extends Vue {
  private get isLangCN () {
    return siteConfig.lang === 'CN'
  }

  private get text () {
    return {
      a: lang('NEW_LANDING_MODULE_C_1'),
      b: lang('NEW_LANDING_MODULE_C_2'),
      c: lang('NEW_LANDING_MODULE_C_3'),
      d: lang('NEW_LANDING_MODULE_C_4'),
      e: lang('NEW_LANDING_MODULE_C_5'),
      f: lang('NEW_LANDING_MODULE_C_6'),
      g: lang('NEW_LANDING_MODULE_C_7'),
      h: lang('NEW_LANDING_MODULE_C_8'),
      i: lang('NEW_LANDING_MODULE_C_9')
    }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { lang } from '@/lang'
import { siteConfig } from '@/config/site'

@Component({
  components: {}
})
export default class ModuleD extends Vue {
  private get isLangCN () {
    return siteConfig.lang === 'CN'
  }

  private get text () {
    return {
      a: lang('NEW_LANDING_MODULE_D_0'),
      b: lang('NEW_LANDING_MODULE_D_1'),
      c: lang('NEW_LANDING_MODULE_D_2'),
      d: lang('NEW_LANDING_MODULE_D_3'),
      e: lang('NEW_LANDING_MODULE_D_4'),
      f: lang('NEW_LANDING_MODULE_D_5'),
      g: lang('NEW_LANDING_MODULE_D_6'),
      h: lang('NEW_LANDING_MODULE_D_7'),
      i: lang('NEW_LANDING_MODULE_D_8'),
      j: lang('NEW_LANDING_MODULE_D_9'),
      k: lang('NEW_LANDING_MODULE_D_10')
    }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { priceOffConfig } from '@/config/priceOff'

@Component
export default class PriceOffCountdown extends Vue {
  private leftDays = '00'

  private leftHours = '00'

  private leftMinutes= '00'

  private leftSeconds= '00'

  private timeOut: any

  private get offText () {
    return priceOffConfig.offRate * 100
  }

  private get show () {
    return priceOffConfig.show
  }

  private countdown () {
    const now = new Date()
    const targetDate = new Date(priceOffConfig.endTime)
    const leftTime = Date.parse(targetDate.toString()) - Date.parse(now.toString())

    if (leftTime <= 0) {
      priceOffConfig.show = false
      clearInterval(this.timeOut)
      return
    } else {
      priceOffConfig.show = true
    }

    this.leftDays = ('0' + Math.floor(leftTime / (24 * 3600 * 1000))).slice(-2)
    const l1 = leftTime % (24 * 3600 * 1000)
    this.leftHours = ('0' + Math.floor(l1 / (3600 * 1000))).slice(-2)
    const l2 = l1 % (3600 * 1000)
    this.leftMinutes = ('0' + Math.floor(l2 / (60 * 1000))).slice(-2)
    const l3 = l2 % (60 * 1000)
    this.leftSeconds = ('0' + Math.floor(l3 / 1000)).slice(-2)
  }

  mounted (): void {
    this.timeOut = setInterval(() => {
      this.countdown()
    }, 1000)

    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timeOut)
    })
  }
}

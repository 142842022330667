import axios from 'axios'
import { isDev } from '@/utils/common'

export function checkFrom (): void {
  const utm_source = getQuery('utm_source')

  if (utm_source) {
    window.localStorage.setItem('source', utm_source + '#' + Date.parse(new Date().toString()))
  }
}

function getQuery (key: string): string | null {
  const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  return r != null ? unescape(r[2]) : null
}

export function successRegister (address: string, name: string, tx: string) {
  // 测试环境暂时没有实现这个接口
  const baseUrl = isDev() ? 'https://api-rpc.pns.link' : 'https://api-rpc.pns.link'
  const source = window.localStorage.getItem('source')

  let utm_source = ''
  if (source) {
    utm_source = source.split('#')[0]
    const time = source.split('#')[1]
    if (Date.parse(new Date().toString()) > Number(time) + 7 * 86400000) {
      window.localStorage.removeItem('source')
      utm_source = ''
    }
  }

  const data = {
    name,
    address,
    tx,
    utm_source
  }

  axios.post(`${baseUrl}/domain/register`, data)
    .catch(e => {
      console.error(e)
      // retry again
      axios.post(`${baseUrl}/domain/register`, data)
  })
}

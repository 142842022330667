
import { Component, Vue, Ref } from 'vue-property-decorator'
import { errorMessage, successMessage } from '@/utils/message'
import { lang } from '@/lang'
import axios from 'axios'

@Component({
  components: {}
})
export default class Subscribe extends Vue {
  private Subscribing = false

  private mail = ''

  @Ref() private form!: HTMLFormElement

  private get text () {
    return lang('LANDING_MODULE_B_EMAIL')
  }

  private async handlSubscribe (): Promise<void> {
    if (!this.mail) {
      errorMessage('Please enter an email address')
      return
    }

    if (!this.checkEmail) {
      errorMessage('Invalid email address')
      return
    }

    this.Subscribing = true
    axios.post('https://try.readme.io/https://api.mailerlite.com/api/v2/subscribers', {
      email: this.mail,
      name: this.mail.split('@')[0],
      resubscribe: false,
      type: 'active'
    }, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-mailerlite-apidocs': 'true',
        'x-mailerlite-apikey': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNWQ0ZWQyNGZhOTI3MGE3NzBlMDg2MjRhZDdlMDYxYjk1ODE3YzQ1NmFmYTlkNzZkNThkMjIzZjY5YTJmMGQ5ZDE3MjBlNDA3M2JiNTdiNDIiLCJpYXQiOjE2NTA3MDIzNjkuMzM2Nzk3LCJuYmYiOjE2NTA3MDIzNjkuMzM2OCwiZXhwIjo0ODA2Mzc1OTY5LjMzMTAwMSwic3ViIjoiNDQzNDEiLCJzY29wZXMiOltdfQ.wkRHG3AqXHFtb9rv1VjbxqvLgFmO1rkWRnty9Ya1zAPP9O2S_TquY8c4WmtewgDJvVYpofLX9pXzs7AZbxrf0qkERf_2FzxbuUZGrQUejTaEoUR4_pgEDVBnz4Q51zWBMJ-c4lAeXvn3-YAtS0g0CLpmNoy2v23QR3sbUmWMrNdAjcTvnC0JeGXJP6a_1_l2BKYMbXlIK6FfrPxERSrCO2KUXDPs604yebaqU9qLg-pK4BYuPSenFggewwMI43hbdDNAyvDVBy763bsncZytThzLtpGH4ueD4qBzCMamZGOhI4azhnCXzr8gnr6PtfDWaQRYJaLuATEWMN6067M4BPQPswgEU90600cDa4EIMJBI-K9vTVWsmMqRurmeuArTVhsQCcr0-dr7S6CADWH-7IBx09QNYEiEXBhrCQ9-ta5oiMyM0gQy8shxQvnSIgyPksrHle-oPgWkrK5kbj3irlQNYxlvVkeSlKFzAUhlkPLWIsQYLI6ZCoKoLt6j810Ss1Djhklf-aHFUbaFeUd-pAUMZrJRaPoUsvDW-LgCiyppdB_ffIVJoS3vZPgOe6HiOReJhBJ_0cMf1D3gIWJ7khvw6_HgsaUlQoDpU92Bt4guWOVWGo3FjUBxb3EC84vK9KWgMgEPN3j95Ps7hyMpHXoKQ9K3FZ7pQMCUZaSjIWs'
      }
    }).then(res => {
      successMessage('subscribe success!')
    })
      .catch(e => {
        console.error('[app-subscribe]' + e.message)
        errorMessage(e.message)
      }).finally(() => {
        this.Subscribing = false
      })
  }

  private checkEmail (str: string) :boolean {
    const re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
    return re.test(str)
  }
}

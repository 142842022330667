
import { Component, Vue } from 'vue-property-decorator'
import { siteConfig } from '@/config/site'
import { lang } from '@/lang'

@Component({
  components: {}
})
export default class ModuleI extends Vue {
  private get isLangCN () {
    return siteConfig.lang === 'CN'
  }

  private get text () {
    return lang('NEW_LANDING_MODULE_I_1')
  }
}
